import { t } from '@local/translations'
import { Button } from '@toasttab/buffet-pui-buttons'
import { IdentityProvider } from '../../types'

export type LoginWithProviderButtonProps = {
  identityProvider: IdentityProvider
  children?: React.ReactNode
}

const LoginWithProviderButton = (props: LoginWithProviderButtonProps) => {
  const { identityProvider: idp, children } = props
  return (
    <Button
      key={idp.provider}
      className='w-full rounded-[4px]'
      data-testid={`login-button-${idp.provider}`}
      onClick={() => (window.location.href = idp.loginURL)}
      style={{
        '--btn-color': '#FF4C00',
        '--btn-color-hover': '#dc3500'
      }}
    >
      {idp ? (
        t('login-page.body.login-with-provider.button', {
          organizationIdentityProvider: idp.name
        })
      ) : (
        <>{children}</>
      )}
    </Button>
  )
}

export default LoginWithProviderButton
