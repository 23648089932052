type LoginPageIllustrationProps = {
  className?: string
}

const LoginPageIllustration = (props: LoginPageIllustrationProps) => {
  return (
    <svg
      className={props.className}
      width='600'
      height='238'
      viewBox='0 0 600 238'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_408_1023'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='600'
        height='238'
      >
        <rect
          width='600'
          height='238'
          transform='matrix(1 0 0 -1 0 238)'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_408_1023)'>
        <path
          d='M16.1633 42.8085V125.528'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.9292 44.1946C17.098 44.1946 16.4242 43.5208 16.4242 42.6897C16.4242 41.8585 17.098 41.1847 17.9292 41.1847H97.7029H99.2079V44.1946H97.7029H17.9292Z'
          fill='#E5E5E5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M56.7774 16.5818C55.9463 16.5818 55.2725 15.908 55.2725 15.0769C55.2725 14.2456 55.9463 13.5719 56.7774 13.5719H125.056H126.561V16.5818H125.056H56.7774Z'
          fill='#E5E5E5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M64.1277 92.5311C63.2965 92.5311 62.6227 91.8572 62.6227 91.0261C62.6227 90.1948 63.2965 89.5211 64.1277 89.5211H153.604C154.435 89.5211 155.109 90.1948 155.109 91.0261C155.109 91.8572 154.435 92.5311 153.604 92.5311H64.1277Z'
          fill='#E5E5E5'
        />
        <path
          d='M184.042 119.457C184.042 118.629 183.371 117.957 182.542 117.957C181.714 117.957 181.042 118.629 181.042 119.457H184.042ZM181.042 165.843C181.042 166.671 181.714 167.343 182.542 167.343C183.371 167.343 184.042 166.671 184.042 165.843H181.042ZM181.32 120.522C181.908 121.105 182.858 121.102 183.441 120.514C184.025 119.926 184.022 118.976 183.434 118.393L181.32 120.522ZM154.991 90.1555C154.403 89.5718 153.453 89.5753 152.869 90.1632C152.286 90.7511 152.289 91.7008 152.877 92.2845L154.991 90.1555ZM123.421 119.261C122.833 119.844 122.829 120.794 123.413 121.382C123.997 121.97 124.946 121.973 125.534 121.39L123.421 119.261ZM154.814 92.3221C155.402 91.7385 155.405 90.7887 154.822 90.2008C154.238 89.6129 153.288 89.6094 152.7 90.1931L154.814 92.3221ZM181.042 119.457V165.843H184.042V119.457H181.042ZM183.434 118.393L154.991 90.1555L152.877 92.2845L181.32 120.522L183.434 118.393ZM125.534 121.39L154.814 92.3221L152.7 90.1931L123.421 119.261L125.534 121.39Z'
          fill='#E5E5E5'
        />
        <path
          d='M153.332 89.2758C152.748 89.8631 152.75 90.8128 153.337 91.3971C153.925 91.9814 154.874 91.979 155.459 91.3917L153.332 89.2758ZM182.042 64.6719C182.626 64.0847 182.623 63.1349 182.036 62.5506C181.449 61.9664 180.499 61.9688 179.915 62.5561L182.042 64.6719ZM208.822 93.3959C209.408 93.9812 210.358 93.9803 210.943 93.3939C211.529 92.8076 211.528 91.8578 210.941 91.2726L208.822 93.3959ZM182.202 62.588C181.616 62.0027 180.666 62.0036 180.081 62.59C179.496 63.1763 179.497 64.1261 180.083 64.7113L182.202 62.588ZM262.813 92.2168C263.4 92.802 264.35 92.8012 264.935 92.2148C265.52 91.6285 265.519 90.6788 264.933 90.0935L262.813 92.2168ZM242.865 68.0661C242.279 67.4809 241.329 67.4817 240.744 68.0681C240.158 68.6544 240.159 69.6041 240.746 70.1894L242.865 68.0661ZM265.528 91.3171C265.528 90.4887 264.856 89.8171 264.028 89.8171C263.199 89.8171 262.528 90.4887 262.528 91.3171H265.528ZM262.528 136.181C262.528 137.009 263.199 137.681 264.028 137.681C264.856 137.681 265.528 137.009 265.528 136.181H262.528ZM155.459 91.3917L182.042 64.6719L179.915 62.5561L153.332 89.2758L155.459 91.3917ZM210.941 91.2726L182.202 62.588L180.083 64.7113L208.822 93.3959L210.941 91.2726ZM264.933 90.0935L242.865 68.0661L240.746 70.1894L262.813 92.2168L264.933 90.0935ZM262.528 91.3171V136.181H265.528V91.3171H262.528Z'
          fill='#E5E5E5'
        />
        <path
          d='M226.886 68.9244L221.588 63.637'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M210.354 93.467C209.524 93.467 208.849 92.7932 208.849 91.962C208.849 91.1308 209.524 90.457 210.354 90.457H263.052H264.557V93.467H263.052H210.354Z'
          fill='#E5E5E5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M183.065 151.423C182.234 151.423 181.56 150.749 181.56 149.918C181.56 149.087 182.234 148.413 183.065 148.413H226.248H227.753V151.423H226.248H183.065Z'
          fill='#E5E5E5'
        />
        <path
          d='M17.7196 118.173C17.7196 117.345 17.048 116.673 16.2196 116.673C15.3912 116.673 14.7196 117.345 14.7196 118.173H17.7196ZM14.7196 150.361C14.7196 151.19 15.3912 151.861 16.2196 151.861C17.048 151.861 17.7196 151.19 17.7196 150.361H14.7196ZM72.1393 119.156C72.1393 118.328 71.4677 117.656 70.6393 117.656C69.8109 117.656 69.1393 118.328 69.1393 119.156H72.1393ZM69.1393 155.188C69.1393 156.017 69.8109 156.688 70.6393 156.688C71.4677 156.688 72.1393 156.017 72.1393 155.188H69.1393ZM123.003 119.445C123.576 120.043 124.526 120.063 125.124 119.49C125.722 118.917 125.742 117.967 125.169 117.369L123.003 119.445ZM99.3418 90.4152C98.7686 89.817 97.8191 89.7967 97.2209 90.3699C96.6228 90.943 96.6025 91.8926 97.1757 92.4907L99.3418 90.4152ZM125.74 119.156C125.74 118.328 125.068 117.656 124.24 117.656C123.411 117.656 122.74 118.328 122.74 119.156H125.74ZM122.74 171.658C122.74 172.486 123.411 173.158 124.24 173.158C125.068 173.158 125.74 172.486 125.74 171.658H122.74ZM211.089 93.3056C211.089 92.4771 210.417 91.8056 209.589 91.8056C208.76 91.8056 208.089 92.4771 208.089 93.3056H211.089ZM208.089 148.744C208.089 149.572 208.76 150.244 209.589 150.244C210.417 150.244 211.089 149.572 211.089 148.744H208.089ZM14.7196 118.173V150.361H17.7196V118.173H14.7196ZM69.1393 119.156V155.188H72.1393V119.156H69.1393ZM125.169 117.369L99.3418 90.4152L97.1757 92.4907L123.003 119.445L125.169 117.369ZM122.74 119.156V171.658H125.74V119.156H122.74ZM208.089 93.3056V148.744H211.089V93.3056H208.089Z'
          fill='#E5E5E5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M72.3326 120.718C71.5014 120.718 70.8276 120.044 70.8276 119.213C70.8276 118.383 71.5014 117.708 72.3326 117.708H125.03H126.535V120.718H125.03H72.3326Z'
          fill='#E5E5E5'
        />
        <path
          d='M70.4851 118.995L48.7783 97.3281'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M16.3737 118.173L37.5518 97.0342'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M16.1513 118.173L-1.20081 100.853'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M37.4096 81.747V96.898'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M48.537 81.747V96.898'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M63.2522 81.747V91.0229'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M49.1255 91.4529H63.1277'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M37.3533 81.4653H63.1277'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M489.031 105.217C489.031 104.389 488.359 103.717 487.531 103.717C486.702 103.717 486.031 104.389 486.031 105.217H489.031ZM486.031 135.357C486.031 136.185 486.702 136.857 487.531 136.857C488.359 136.857 489.031 136.185 489.031 135.357H486.031ZM486.626 104.159C486.041 104.746 486.044 105.696 486.631 106.28C487.218 106.865 488.168 106.862 488.752 106.275L486.626 104.159ZM516.143 78.7428C516.727 78.1555 516.725 77.2058 516.138 76.6215C515.55 76.0372 514.6 76.0397 514.016 76.627L516.143 78.7428ZM542.382 107.121C542.966 107.708 543.916 107.711 544.503 107.126C545.091 106.542 545.093 105.592 544.509 105.005L542.382 107.121ZM516.314 76.6637C515.729 76.0764 514.78 76.0739 514.192 76.6582C513.605 77.2425 513.602 78.1922 514.187 78.7795L516.314 76.6637ZM545.104 106.23C545.104 105.402 544.432 104.73 543.604 104.73C542.775 104.73 542.104 105.402 542.104 106.23H545.104ZM542.104 130.201C542.104 131.029 542.775 131.701 543.604 131.701C544.432 131.701 545.104 131.029 545.104 130.201H542.104ZM599.435 107.125C600.022 107.71 600.971 107.709 601.557 107.123C602.142 106.536 602.141 105.586 601.555 105.001L599.435 107.125ZM574.36 77.8576C573.773 77.2724 572.824 77.2733 572.238 77.8596C571.653 78.4459 571.654 79.3957 572.24 79.9809L574.36 77.8576ZM486.031 105.217V135.357H489.031V105.217H486.031ZM488.752 106.275L516.143 78.7428L514.016 76.627L486.626 104.159L488.752 106.275ZM544.509 105.005L516.314 76.6637L514.187 78.7795L542.382 107.121L544.509 105.005ZM542.104 106.23V130.201H545.104V106.23H542.104ZM601.555 105.001L574.36 77.8576L572.24 79.9809L599.435 107.125L601.555 105.001Z'
          fill='#E5E5E5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M544.172 107.795C543.341 107.795 542.667 107.12 542.667 106.29C542.667 105.458 543.341 104.785 544.172 104.785H600.237H601.742V107.795H600.237H544.172Z'
          fill='#E5E5E5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M515.006 79.342C514.175 79.342 513.501 78.6683 513.501 77.837C513.501 77.0059 514.175 76.332 515.006 76.332H572.944C573.776 76.332 574.449 77.0059 574.449 77.837C574.449 78.6683 573.776 79.342 572.944 79.342H515.006Z'
          fill='#E5E5E5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M484.987 21.1787C484.155 21.1787 483.482 20.5048 483.482 19.6737C483.482 18.8425 484.155 18.1687 484.987 18.1687H528.21C529.041 18.1687 529.715 18.8425 529.715 19.6737C529.715 20.5048 529.041 21.1787 528.21 21.1787H484.987Z'
          fill='#E5E5E5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M421.716 78.3775C420.885 78.3775 420.211 77.7037 420.211 76.8725C420.211 76.0414 420.885 75.3676 421.716 75.3676H457.45H458.955V78.3775H457.45H421.716Z'
          fill='#E5E5E5'
        />
        <path
          d='M543.604 153.231V215.977'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M530.315 167.535L542.97 180.167'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M556.509 167.241L543.853 179.873'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M181.563 63.4131H220.999'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M375.538 104.611C375.538 103.783 374.867 103.111 374.038 103.111C373.21 103.111 372.538 103.783 372.538 104.611H375.538ZM372.538 134.752C372.538 135.58 373.21 136.252 374.038 136.252C374.867 136.252 375.538 135.58 375.538 134.752H372.538ZM373.138 103.549C372.552 104.135 372.551 105.084 373.136 105.671C373.721 106.257 374.671 106.258 375.258 105.673L373.138 103.549ZM396.583 84.387C397.169 83.8018 397.17 82.852 396.585 82.2657C396 81.6793 395.05 81.6785 394.464 82.2637L396.583 84.387ZM428.893 106.52C429.48 107.105 430.43 107.104 431.015 106.518C431.6 105.931 431.599 104.982 431.013 104.396L428.893 106.52ZM408.153 81.5784C407.567 80.9931 406.617 80.994 406.032 81.5803C405.447 82.1666 405.448 83.1164 406.034 83.7016L408.153 81.5784ZM431.613 105.624C431.613 104.796 430.941 104.124 430.113 104.124C429.284 104.124 428.613 104.796 428.613 105.624H431.613ZM428.613 158.383C428.613 159.211 429.284 159.883 430.113 159.883C430.941 159.883 431.613 159.211 431.613 158.383H428.613ZM485.939 106.515C486.524 107.102 487.473 107.105 488.061 106.52C488.648 105.936 488.65 104.986 488.066 104.399L485.939 106.515ZM459.871 76.0578C459.287 75.4705 458.337 75.4681 457.75 76.0523C457.162 76.6366 457.16 77.5863 457.744 78.1736L459.871 76.0578ZM488.662 105.624C488.662 104.796 487.991 104.124 487.162 104.124C486.334 104.124 485.662 104.796 485.662 105.624H488.662ZM485.662 169.477C485.662 170.305 486.334 170.977 487.162 170.977C487.991 170.977 488.662 170.305 488.662 169.477H485.662ZM372.538 104.611V134.752H375.538V104.611H372.538ZM375.258 105.673L396.583 84.387L394.464 82.2637L373.138 103.549L375.258 105.673ZM431.013 104.396L408.153 81.5784L406.034 83.7016L428.893 106.52L431.013 104.396ZM428.613 105.624V158.383H431.613V105.624H428.613ZM488.066 104.399L459.871 76.0578L457.744 78.1736L485.939 106.515L488.066 104.399ZM485.662 105.624V169.477H488.662V105.624H485.662Z'
          fill='#E5E5E5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M431.858 107.189C431.026 107.189 430.353 106.514 430.353 105.684C430.353 104.852 431.026 104.179 431.858 104.179H486.157H487.662V107.189H486.157H431.858Z'
          fill='#E5E5E5'
        />
        <path
          d='M459.843 47.1017C459.843 46.2733 459.171 45.6017 458.343 45.6017C457.515 45.6017 456.843 46.2733 456.843 47.1017H459.843ZM456.843 77.2421C456.843 78.0705 457.515 78.7421 458.343 78.7421C459.171 78.7421 459.843 78.0705 459.843 77.2421H456.843ZM457.438 46.0438C456.854 46.6311 456.856 47.5809 457.444 48.1651C458.031 48.7494 458.981 48.7469 459.565 48.1596L457.438 46.0438ZM486.954 20.6277C487.539 20.0404 487.536 19.0907 486.949 18.5064C486.362 17.9222 485.412 17.9246 484.828 18.5119L486.954 20.6277ZM513.194 49.0061C513.779 49.5934 514.729 49.5958 515.316 49.0115C515.903 48.4272 515.906 47.4775 515.321 46.8902L513.194 49.0061ZM487.125 18.5486C486.541 17.9613 485.591 17.9589 485.004 18.5432C484.416 19.1274 484.414 20.0772 484.998 20.6645L487.125 18.5486ZM515.916 48.1151C515.916 47.2867 515.245 46.6151 514.416 46.6151C513.588 46.6151 512.916 47.2867 512.916 48.1151H515.916ZM512.916 77.3732C512.916 78.2016 513.588 78.8732 514.416 78.8732C515.245 78.8732 515.916 78.2016 515.916 77.3732H512.916ZM570.248 49.0098C570.834 49.595 571.784 49.5941 572.369 49.0078C572.954 48.4215 572.953 47.4717 572.367 46.8865L570.248 49.0098ZM549.146 23.7091C548.56 23.1238 547.61 23.1247 547.025 23.711C546.44 24.2974 546.441 25.2471 547.027 25.8324L549.146 23.7091ZM572.966 48.115C572.966 47.2866 572.294 46.615 571.466 46.615C570.638 46.615 569.966 47.2866 569.966 48.115H572.966ZM569.966 77.3921C569.966 78.2205 570.638 78.8921 571.466 78.8921C572.294 78.8921 572.966 78.2205 572.966 77.3921H569.966ZM456.843 47.1017V77.2421H459.843V47.1017H456.843ZM459.565 48.1596L486.954 20.6277L484.828 18.5119L457.438 46.0438L459.565 48.1596ZM515.321 46.8902L487.125 18.5486L484.998 20.6645L513.194 49.0061L515.321 46.8902ZM512.916 48.1151V77.3732H515.916V48.1151H512.916ZM572.367 46.8865L549.146 23.7091L547.027 25.8324L570.248 49.0098L572.367 46.8865ZM569.966 48.115V77.3921H572.966V48.115H569.966Z'
          fill='#E5E5E5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M516.161 49.0737C515.33 49.0737 514.656 48.3998 514.656 47.5687C514.656 46.7374 515.33 46.0637 516.161 46.0637H570.461H571.966V49.0737H570.461H516.161Z'
          fill='#E5E5E5'
        />
        <path
          d='M97.0688 90.8653C97.0688 91.6938 97.7404 92.3653 98.5688 92.3653C99.3972 92.3653 100.069 91.6938 100.069 90.8653H97.0688ZM98.5688 42.8087L97.5107 41.7455L97.0688 42.1852V42.8087H98.5688ZM112.635 28.8099L113.693 29.8731L113.694 29.8728L112.635 28.8099ZM127.46 16.1657C128.047 15.5812 128.049 14.6315 127.465 14.0444C126.88 13.4574 125.93 13.4553 125.343 14.0398L127.46 16.1657ZM127.461 14.0411C126.875 13.4559 125.925 13.4568 125.34 14.0431C124.755 14.6294 124.756 15.5792 125.342 16.1644L127.461 14.0411ZM152.098 42.87C152.684 43.4553 153.634 43.4544 154.219 42.868C154.804 42.2817 154.803 41.332 154.217 40.7467L152.098 42.87ZM100.069 90.8653V42.8087H97.0688V90.8653H100.069ZM99.6269 43.8719L113.693 29.8731L111.577 27.7467L97.5107 41.7455L99.6269 43.8719ZM113.694 29.8728L127.46 16.1657L125.343 14.0398L111.577 27.7469L113.694 29.8728ZM125.342 16.1644L152.098 42.87L154.217 40.7467L127.461 14.0411L125.342 16.1644Z'
          fill='#E5E5E5'
        />
        <path
          d='M43.2394 28.2957L56.4572 15.1027'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M16.4318 42.1022L32.3243 26.2395'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M153.899 42.7241V89.9841'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M183.727 191.69H214.562'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M178.849 218.961H194.461'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M226.536 236.383L212.342 191.995'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M201.739 236.293L187.627 192.043'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M172.213 236.103L186.464 191.995'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M234.101 170.911H329.603'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M335.97 170.911H366.68'
          stroke='#FF4C00'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M337.545 92.0405H374.142'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M337.545 122.693V92.0405'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M366.679 170.911V235.207'
          stroke='#FF4C00'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M327.73 203.806H250.58'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M327.73 197.078H250.58'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M243.892 215.021V171.285'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M263.688 195.956V181.004H327.891'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M246.276 119.955V160.273'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M264.33 119.955V160.273'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M282.384 119.955V160.273'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M300.925 119.955V160.273'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M319.467 119.955V160.273'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M337.521 119.955V160.273'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M356.063 119.955V160.273'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M373.867 119.955H227.925'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M257.322 216.89V203.806'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M250.58 203.806V197.078'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M236.074 216.89V171.285'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M327.891 216.89V171.285'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M361.096 201.937H364.807'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M227.735 156.624C227.735 161.693 231.824 165.801 236.87 165.801C241.915 165.801 246.005 161.693 246.005 156.624'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M282.547 156.624C282.547 161.693 286.637 165.801 291.682 165.801C296.727 165.801 300.817 161.693 300.817 156.624'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M246.005 156.624C246.005 161.693 250.096 165.801 255.14 165.801C260.186 165.801 264.277 161.693 264.277 156.624'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M300.817 156.624C300.817 161.693 304.993 165.801 310.143 165.801C315.293 165.801 319.469 161.693 319.469 156.624'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M264.277 156.624C264.277 161.693 268.366 165.801 273.412 165.801C278.456 165.801 282.547 161.693 282.547 156.624'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M319.469 156.624C319.469 161.693 323.559 165.801 328.604 165.801C333.649 165.801 337.739 161.693 337.739 156.624'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M337.575 156.624C337.575 161.693 341.664 165.801 346.71 165.801C351.756 165.801 355.845 161.693 355.845 156.624'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M355.845 156.624C355.845 161.693 359.936 165.801 364.98 165.801C370.026 165.801 374.116 161.693 374.116 156.624'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M373.867 107.362H227.925'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M227.735 107.222V235.573'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M291.633 171.354C291.633 176.221 295.586 180.167 300.462 180.167C305.338 180.167 309.291 176.221 309.291 171.354'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M253.962 171.354C253.962 176.221 258.047 180.167 263.086 180.167C268.124 180.167 272.209 176.221 272.209 171.354'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M233.36 217.179H335.779'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M231.595 148.699H230.09V151.709H231.595H374.038H375.543V148.699H374.038H231.595Z'
          fill='#E5E5E5'
        />
        <path
          d='M148.895 207.192H134.179C123.289 207.192 114.461 216.003 114.461 226.873V235.686'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M438.785 211.892H453.501C464.391 211.892 473.219 220.704 473.219 231.574V235.98'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M149.189 206.898C149.189 196.028 158.017 187.217 168.908 187.217'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M38.2362 236.273C38.2362 225.404 29.408 216.592 18.5178 216.592'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M557.097 236.273C557.097 225.404 565.925 216.592 576.816 216.592'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M437.904 210.423C437.904 199.554 429.074 190.742 418.184 190.742'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M388.754 210.423C388.754 199.554 397.582 190.742 408.472 190.742H417.89'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M504.121 190.742C509.703 190.742 514.744 193.056 518.33 196.777'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M552.977 235.98C552.977 223.133 544.545 214.169 532.905 211.201'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M444.083 210.423C444.083 199.554 452.912 190.742 463.802 190.742'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M168.613 186.923C168.613 176.053 177.441 167.241 188.331 167.241'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M18.8121 216.298C18.8121 205.428 9.98382 196.617 -0.906372 196.617'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M576.521 216.298C576.521 205.428 585.349 196.617 596.24 196.617'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M504.416 190.448C504.416 179.578 495.588 170.767 484.697 170.767'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M-1.495 177.229C-1.495 161.006 11.6815 147.854 27.9356 147.854H30.5844'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M60.3092 177.229C60.3092 161.006 73.4857 147.854 89.7397 147.854'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M209.522 186.923C209.522 176.053 200.693 167.241 189.803 167.241'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M463.507 190.448C463.507 179.578 472.336 170.767 483.226 170.767'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M59.7206 177.229C59.7206 161.006 46.544 147.854 30.29 147.854'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M90.0341 147.854H93.86C108.202 147.854 121.325 158.093 123.938 171.648'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M123.879 169.592C123.879 178.352 131.022 185.454 139.832 185.454H148.601'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M-1.49502 235.062H-3V238.072H-1.49502H238.953C239.784 238.072 240.458 237.399 240.458 236.567C240.458 235.737 239.784 235.062 238.953 235.062H-1.49502Z'
          fill='#FF4C00'
        />
        <path
          d='M216.347 48.259V63.4099'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M226.297 48.259V68.1101'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M240.424 48.8464V68.2462'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M216.88 47.9772H240.299'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M534.73 25.4489L529.432 20.1614'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M524.191 4.78394V19.9349'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M534.73 4.78394V24.635'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M548.267 5.37085V24.7705'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M524.724 4.50166L548.143 4.50165'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M395.874 63.5342V82.7977'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M407.001 63.5342V82.7977'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M421.716 63.5342V82.2103'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M407.589 82.6403H421.592'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M395.817 63.2525H421.592'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M32.6444 5.95837V26.3969'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M42.5944 5.95837V28.1595'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M56.7211 6.5459V14.1954'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M33.1767 5.67671L56.5967 5.6767'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M374.17 92.6044V235.393'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M441.14 211.892H374.038'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M427.447 211.913L407.801 220.275'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M388.156 211.913L407.802 220.275'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M407.589 150.204C425.794 150.204 440.551 164.773 440.551 183.105H374.627C374.627 164.773 389.385 150.204 407.589 150.204Z'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M407.884 150.204C414.548 150.204 419.95 164.934 419.95 183.105H395.817C395.817 164.934 401.219 150.204 407.884 150.204Z'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M408.178 212.48V236.567'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M408.178 184.279V210.13'
          stroke='#A9A9A9'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M335.969 170.911V236.595'
          stroke='#FF4C00'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M226.748 235.062C225.917 235.062 225.243 235.737 225.243 236.567C225.243 237.399 225.917 238.072 226.748 238.072H336.195C337.027 238.072 337.7 237.399 337.7 236.567C337.7 235.737 337.027 235.062 336.195 235.062H226.748Z'
          fill='#FF4C00'
        />
        <rect
          x='37.3533'
          y='127.878'
          width='11.1836'
          height='11.1627'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='148.601'
          y='127.878'
          width='11.1836'
          height='11.1627'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='395.817'
          y='120.241'
          width='11.1836'
          height='11.1627'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='452.913'
          y='120.241'
          width='11.1836'
          height='11.1627'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='452.913'
          y='120.241'
          width='11.1836'
          height='11.1627'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='509.419'
          y='120.241'
          width='11.1836'
          height='11.1627'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='120.936'
          y='52.6774'
          width='11.1836'
          height='11.1627'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='480.578'
          y='57.3774'
          width='11.1836'
          height='11.1627'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.601 185.454V158.429H159.784V184.866V185.454H168.908'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M366.679 235.062C365.848 235.062 365.174 235.737 365.174 236.567C365.174 237.399 365.848 238.072 366.679 238.072H600.237H601.742V235.062H600.237H366.679Z'
          fill='#FF4C00'
        />
        <path
          d='M542.382 131.404C557.01 131.404 568.869 143.167 568.869 157.678V173.705'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M568.869 173.705C568.869 188.306 557.01 200.142 542.382 200.142'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M543.559 131.404C528.93 131.404 517.071 143.167 517.071 157.678V173.705'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M517.071 173.705C517.071 188.306 528.93 200.142 543.559 200.142'
          stroke='#E5E5E5'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </g>
    </svg>
  )
}

export default LoginPageIllustration
