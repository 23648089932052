import React from 'react'
import cx from 'classnames'
import { BaseProps, SizeMap, SIZE } from '../utils'

export type ToastBrandProps = BaseProps & JSX.IntrinsicElements['svg']

export const ToastBrand = ({
  size = 'xs',
  strokeColor = 'text-brand-50',
  fillColor = '',
  testId = 'toast-brand',
  className = '',
  ...props
}: ToastBrandProps) => (
  <svg
    className={cx(
      'fill-current align-middle leading-none',
      SizeMap[size],
      strokeColor,
      fillColor,
      className
    )}
    data-testid={testId}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 491.95 126.73'
    {...props}
  >
    <path d='M175.16,118.29c-13.86,0-21.34-7.16-21.34-20.56V58.49H149a8.72,8.72,0,0,1-8.57-8.88A8.61,8.61,0,0,1,149,41.2h4.82V29.52a10,10,0,0,1,19.94,0V41.2h7.78a8.65,8.65,0,0,1,0,17.29h-7.78V92.44c0,5.92,3.74,8.25,6.85,8.25h1.71c5.61,0,8.73,3.74,8.73,8.57S188.09,118.29,175.16,118.29Z' />
    <path d='M237.46,118.29c-24.76,0-39.72-18.06-39.72-39.56,0-21.33,15-39.4,39.72-39.4,24.92,0,39.87,18.07,39.87,39.4C277.33,100.23,262.38,118.29,237.46,118.29Zm0-61.36c-12.31,0-19.16,10.12-19.16,21.8,0,11.84,6.85,22,19.16,22s19.31-10.12,19.31-22C256.77,67.05,249.76,56.93,237.46,56.93Z' />
    <path d='M347.26,117.36H345.7c-1.56,0-8.57-1.25-8.57-8.72-5.13,6.07-14,9.65-23.82,9.65-12,0-26.17-8.1-26.17-24.92,0-17.6,14.17-24.29,26.17-24.29,10,0,18.84,3.27,23.82,9.19V68.14c0-7.63-6.54-12.61-16.5-12.61a29.72,29.72,0,0,0-16.82,4.83,7.45,7.45,0,0,1-3.58,1.09,7.6,7.6,0,0,1-7.48-7.63,8.12,8.12,0,0,1,1.09-3.9c4.67-7.47,21.49-10.59,30.21-10.59,17.13,0,32.86,6.85,32.86,28.5v39.72A9.74,9.74,0,0,1,347.26,117.36ZM337.13,89c-3.27-4.36-9.49-6.54-15.88-6.54-7.79,0-14.17,4-14.17,11.37,0,7,6.38,11.06,14.17,11.06,6.39,0,12.61-2.18,15.88-6.54Z' />
    <path d='M403.64,118.29c-9.81,0-25.54-3.27-30.21-10a6.77,6.77,0,0,1-1.4-4.2,8.4,8.4,0,0,1,8.25-8.25A7.55,7.55,0,0,1,384.33,97c5.92,3.42,13.7,6.07,20.24,6.07,8.57,0,12.62-3.43,12.62-8.1,0-12.46-44.7-2.34-44.7-31.93,0-12.61,11.06-23.67,30.84-23.67,9.81,0,23.2,3.12,27.41,9.19A6.79,6.79,0,0,1,432,52.41a7.74,7.74,0,0,1-7.78,7.48,7.85,7.85,0,0,1-3.74-.93,34.44,34.44,0,0,0-17-4.52c-7.16,0-11.83,3.27-11.83,7.48,0,11.21,44.54,1.86,44.54,32.23C436.19,107.86,424.51,118.29,403.64,118.29Z' />
    <path d='M476.06,118.29c-13.86,0-21.34-7.16-21.34-20.56V58.49H449.9a8.72,8.72,0,0,1-8.57-8.88,8.62,8.62,0,0,1,8.57-8.41h4.82V29.52a10,10,0,0,1,19.94,0V41.2h7.79a8.65,8.65,0,0,1,0,17.29h-7.79V92.44c0,5.92,3.74,8.25,6.85,8.25h1.72c5.6,0,8.72,3.74,8.72,8.57S489,118.29,476.06,118.29Z' />
    <path d='M127.71,87.33a227.89,227.89,0,0,0-1.07-24.94c-.6-5.6-2.48-12.76-8.43-14.22a4.14,4.14,0,0,0,2.33-.08c6.92-3.68,6.39-12.74,3.94-19.29-3.21-8.59-10.86-14.67-18.42-18.71C78.89-4.43,42.53-3.21,16.19,13.33,9.34,17.63,1.44,26,4.91,35.35A19,19,0,0,0,9,41.55c.77.83,2.61,2.76,2.61,2.77C8.08,49.19,5.5,53.57,3.84,59.49-1.15,77.27-.32,88.7,1.05,103c.85,8.84,3.73,22,13.75,23.46h0c8.17,1.15,17-1.59,25.18-2.56,9-1.08,18.08-2.76,27.16-2.52,12.82.33,25.54,3.43,38.38,3.55,6.78.07,18.05.72,20.07-8.35,1.92-8.63,2-17.63,2-26.44C127.7,89.2,127.71,88.26,127.71,87.33Zm-14.66,14.93c-3.19,7.31-12.22,6.49-18.51,5.95-9.42-.81-18.69-3.12-28.16-3.41-8.38-.25-16.67,1.36-24.84,3.18-6.11,1.37-14.63,4.94-20.15.16-6.12-5.28-7.22-14.3-7.3-21.87a128.72,128.72,0,0,1,2-20.44,58.84,58.84,0,0,1,3.59-12.15c2.2-5.66,5.43-9.06,5.6-8.88-1.65-1.73-3.62-2.65-4.73-5-4.18-8.9,6.27-16.42,12-19,20-9.09,48-8.19,66.9,3.48,5.89,3.63,17.33,16.15,6.64,22.38,6.59,3.16,8.26,12.71,9,19.49a120.3,120.3,0,0,1-1,32.66A14.94,14.94,0,0,1,113.05,102.26Z' />
  </svg>
)

ToastBrand.Size = SIZE
