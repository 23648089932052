import { LoginPage } from '@local/login-page'
import { Navigate, RouteObject } from 'react-router-dom'

export const AppRoute = {
  Root: '/',
  OrganizationLogin: '/:organizationSlug'
}

export const appRouteConfig: RouteObject[] = [
  {
    path: AppRoute.Root,
    Component: LoginPage,
    children: [
      {
        path: AppRoute.OrganizationLogin,
        Component: LoginPage
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to={AppRoute.Root} replace />
  }
]
