import * as React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { BanquetProps } from 'banquet-runtime-modules'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { appRouteConfig } from '@local/routes'
import { reactQueryConfigs } from '@local/shared'

const router = createBrowserRouter(appRouteConfig, {
  basename: '/sso',
  future: {
    v7_relativeSplatPath: true
  }
})

const queryClient = new QueryClient(reactQueryConfigs)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const App = (props?: BanquetProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider
        router={router}
        future={{
          v7_startTransition: true
        }}
      />
    </QueryClientProvider>
  )
}
