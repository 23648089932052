import { IdentityProvider } from '@local/shared'
import LoginToOrganizationHeading from '@local/shared/components/LoginToOrganizationHeading/LoginToOrganizationHeading'
import LoginWithProviderButton from '@local/shared/components/LoginWithProviderButton/LoginWithProviderButton'
import { ToastBrand } from '@toasttab/buffet-pui-toast-logo'
import LoginPageIllustration from '../LoginPageIllustration/LoginPageIllustration'

export type LoginPageBodyNoOrgLogoProps = {
  organizationName: string
  identityProviders: IdentityProvider[]
}

const LoginPageBodyNoOrgLogo = (props: LoginPageBodyNoOrgLogoProps) => {
  const { organizationName, identityProviders } = props

  return (
    <div className='max-w-[600px] mx-auto flex flex-col items-center gap-8'>
      <ToastBrand size='xs' testId='toast-logo' data-testid='toast-logo' />

      <LoginToOrganizationHeading organizationName={organizationName} />

      <div className='flex flex-col w-full gap-4'>
        {identityProviders
          .filter((p) => !!p.loginURL)
          .map((provider) => (
            <LoginWithProviderButton
              key={provider.provider}
              identityProvider={provider}
            />
          ))}
      </div>

      <LoginPageIllustration className='m-auto overflow-hidden w-full h-auto' />
    </div>
  )
}

export default LoginPageBodyNoOrgLogo
