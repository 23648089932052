import { t, Trans } from '@local/translations'
import cx from 'classnames'

export type LoginPageFooterProps = {
  className?: string
}

const LoginPageFooter = (props: LoginPageFooterProps) => {
  const { className } = props
  return (
    <footer className={cx('px-4 pt-8 md:pt-4 pb-12', className)}>
      <nav className='flex flex-wrap md:flex-nowrap items-center justify-center text-default gap-3 type-caption text-center'>
        <span data-testid='footer-powered-by-toast'>
          {
            <Trans
              i18nKey='login-page.footer.powered-by-toast'
              components={[
                // eslint-disable-next-line jsx-a11y/anchor-has-content -- content is provided by the translation
                <a
                  key='powered-by-toast-link'
                  className='hover:underline'
                  href='https://pos.toasttab.com'
                  target='_blank'
                  rel='noopener noreferrer'
                />
              ]}
            />
          }
        </span>
        <span className='hidden md:inline-block text-[#d9d9d952]'>•</span>
        <span data-testid='footer-copyright'>
          {t('login-page.footer.copyright', {
            fullYear: new Date().getFullYear()
          })}
        </span>
        <span className='hidden md:inline-block text-[#d9d9d952]'>•</span>
        <div className='flex flex-wrap justify-center gap-3'>
          <a
            className='hover:underline'
            href={'https://pos.toasttab.com/privacy'}
            data-testid={'footer-privacy-policy'}
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('login-page.footer.privacy-policy')}
          </a>
          <span className='hidden md:inline-block text-[#d9d9d952]'>•</span>
          <a
            className='hover:underline'
            href={'https://pos.toasttab.com/terms-of-service'}
            data-testid={'footer-terms-of-service'}
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('login-page.footer.terms-of-service')}
          </a>
          <span className='hidden md:inline-block text-[#d9d9d952]'>•</span>
          <a
            className='hover:underline'
            href={'https://pos.toasttab.com/blog'}
            data-testid={'footer-toast-blog'}
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('login-page.footer.toast-blog')}
          </a>
        </div>
      </nav>
    </footer>
  )
}

export default LoginPageFooter
