import { IdentityProvider, OrganizationConfigLogo } from '@local/shared'
import LoginToOrganizationHeading from '@local/shared/components/LoginToOrganizationHeading/LoginToOrganizationHeading'
import LoginWithProviderButton from '@local/shared/components/LoginWithProviderButton/LoginWithProviderButton'
import OrganizationLogo from '@local/shared/components/OrganizationLogo/OrganizationLogo'

export type LoginPageBodyWithOrgLogoProps = {
  organizationName: string
  logo: OrganizationConfigLogo
  identityProviders: IdentityProvider[]
}

const LoginPageBodyWithOrgLogo = (props: LoginPageBodyWithOrgLogoProps) => {
  const { organizationName, logo, identityProviders } = props

  return (
    <div className='max-w-[560px] mx-auto min-h-[360px] px-6 sm:p-12 flex flex-col items-center justify-center gap-6 sm:gap-8 card-elevation card-base'>
      <OrganizationLogo organizationName={organizationName} logo={logo} />

      <LoginToOrganizationHeading organizationName={organizationName} />

      <div className='flex flex-col w-full gap-4'>
        {identityProviders
          .filter((p) => !!p.loginURL)
          .map((provider) => (
            <LoginWithProviderButton
              key={provider.provider}
              identityProvider={provider}
            />
          ))}
      </div>
    </div>
  )
}

export default LoginPageBodyWithOrgLogo
