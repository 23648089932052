import { Trans } from '@local/translations'

export type LoginToOrganizationHeadingProps = {
  organizationName?: string
  children?: React.ReactNode
}

const LoginToOrganizationHeading = (props: LoginToOrganizationHeadingProps) => {
  const { organizationName, children } = props
  return (
    <h1 className='text-center font-effra type-headline-3'>
      {organizationName ? (
        <Trans
          i18nKey='login-page.body.login-to-organization.title'
          components={[
            <strong
              key={'login-page.body.login-to-organization.title'}
              className='font-bold'
            />
          ]}
          values={{ organizationName }}
        />
      ) : (
        <>{children}</>
      )}
    </h1>
  )
}

export default LoginToOrganizationHeading
