/**
 * Preferably for non-typescript use. Maps the key to itself as a string:
 * SIZE.xxs === 'xxs'
 */
export const SIZE: { [key in string]: key } = Object.freeze({
  xxs: 'xxs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl'
})

export type Size = keyof typeof SIZE

/**
 * To be used internally by Toast* components to get the proper tailwind classes
 * based on given size.
 */
export const SizeMap: { [key in Size]: string } = {
  xxs: 'h-6',
  xs: 'h-8',
  sm: 'h-10',
  md: 'h-12',
  lg: 'h-16',
  xl: 'h-24',
  xxl: 'h-32'
}

export interface BaseProps {
  /** Determines the height of the logo, with an auto width */
  size?: Size
  /** Tailwind utility or CSS class to define stroke color */
  strokeColor?: string
  /** Tailwind utility or CSS class to define the background color */
  fillColor?: string
  /** TestId for the SVG */
  testId?: string
  /** Can be Tailwind utility or CSS classes */
  className?: string
}
