import { OrganizationConfigLogo } from '@local/shared'
import { Image } from '@toasttab/buffet-pui-image'

export type OrganizationLogoProps = {
  logo: OrganizationConfigLogo
  organizationName?: string
}

const OrganizationLogo = (props: OrganizationLogoProps) => {
  const { logo, organizationName } = props
  return (
    <Image
      src={logo.url}
      alt={`${organizationName ? `${organizationName} ` : ''}Logo`}
      className={'max-h-[75px]'}
      style={{
        width: logo.width ? logo.width : '100%',
        height: logo.height ? logo.height : '100%'
      }}
      fit='contain'
      testId='organization-logo'
    />
  )
}

export default OrganizationLogo
