import envFromHostname from '@toasttab/env-from-hostname'

export const environment = envFromHostname(window.location.hostname) as
  | 'dev'
  | 'preprod'
  | 'prod'
  | 'sandbox'

export const isDevelopment: boolean = environment === 'dev'
export const isPreproduction: boolean = environment === 'preprod'
export const isProduction: boolean = environment === 'prod'
export const isSandbox: boolean = environment === 'sandbox'

export const PUBLIC_ASSETS_URL = isProduction
  ? 'https://d28f3w0x9i80nq.cloudfront.net'
  : 'https://d1knuqod36pnys.cloudfront.net'
