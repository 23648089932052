import { OrganizationConfig } from '@local/shared'
import LoginPageBodyNoOrgLogo from '../LoginPageBodyNoOrgLogo/LoginPageBodyNoOrgLogo'
import LoginPageFooter from '../LoginPageFooter/LoginPageFooter'

type LoginPageViewNoOrgLogoProps = {
  config: OrganizationConfig
}

const LoginPageViewNoOrgLogo = (props: LoginPageViewNoOrgLogoProps) => {
  const { config } = props

  return (
    <>
      <main className='pt-[15dvh] sm:pt-[18dvh] mb-auto px-4'>
        <LoginPageBodyNoOrgLogo
          organizationName={config.name}
          identityProviders={config.identityProviders}
        />
      </main>
      <LoginPageFooter />
    </>
  )
}

export default LoginPageViewNoOrgLogo
