import { t } from '@local/translations'

import {
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading
} from '@toasttab/buffet-pui-error-pages'

import {
  Error500DesktopIllustration,
  Error500MobileIllustration
} from '@toasttab/buffet-pui-illustrations'
import { ToastBrand } from '@toasttab/buffet-pui-toast-logo'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { useMemo } from 'react'
import { useTitle } from 'react-use'
import LoginPageFooter from '../LoginPageFooter/LoginPageFooter'

export type LoginErrorPageProps = {
  heading?: string
  subheading?: string
}

const LoginErrorPage = (props: LoginErrorPageProps) => {
  const { heading, subheading } = props

  const screenSize = useScreenSize()
  const isMobile = useMemo(() => screenSize <= ScreenSize.XS, [screenSize])

  useTitle(t('login-page.document.title.error'))

  return (
    <div className='type-default h-dvh flex flex-col bg-white'>
      <header className='min-h-[48px] h-[48px] sm:h-[64px] bg-white flex items-center justify-start drop-shadow-sm'>
        <div className='flex items-center justify-center h-full px-4 sm:px-10 py-2 bg-brand-50'>
          <ToastBrand
            size={isMobile ? 'xxs' : 'xs'}
            fillColor='bg-brand-50'
            strokeColor='text-white'
          />
        </div>
      </header>
      <main className='max-w-[560px] mx-auto mb-auto flex items-start justify-center'>
        <ErrorContainer>
          <div className='mb-4 flex items-center justify-center'>
            {isMobile ? (
              <Error500MobileIllustration resizeToContainer />
            ) : (
              <Error500DesktopIllustration />
            )}
          </div>

          <ErrorHeading>
            {heading || t('login-page.error.generic.title')}
          </ErrorHeading>
          <ErrorSubheading>
            {subheading || t('login-page.error.generic.description')}
          </ErrorSubheading>
        </ErrorContainer>
      </main>
      <LoginPageFooter />
    </div>
  )
}

export default LoginErrorPage
