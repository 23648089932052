import { t } from '@local/translations'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { isString } from '@toasttab/buffet-utils'
import cx from 'classnames'
import { useParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import LoginErrorPage from './components/LoginErrorPage/LoginErrorPage'
import LoginPageViewNoOrgLogo from './components/LoginPageViewNoOrgLogo/LoginPageViewNoOrgLogo'
import LoginPageViewWithOrgLogo from './components/LoginPageViewWithOrgLogo/LoginPageViewWithOrgLogo'
import { useOrganizationConfig } from './hooks/useOrganizationConfig.hook'

export interface LoginPageProps {
  testId?: string | number
  organizationSlug?: string
}

export const LoginPage = (props: LoginPageProps) => {
  const { testId } = props

  // Get organization slug from URL
  const { organizationSlug: urlOrganizationSlug } = useParams()
  const organizationSlug = props.organizationSlug || urlOrganizationSlug

  const { config, loading, error } = useOrganizationConfig(organizationSlug)

  useTitle(
    config
      ? t('login-page.document.title.with-organization-name', {
          organizationName: config.name
        })
      : t('login-page.document.title.default')
  )

  if (loading) {
    return (
      <div className='flex items-center justify-center h-screen'>
        <MerryGoRound size={'lg'} />
      </div>
    )
  }

  if (!organizationSlug || !config || error) {
    return <LoginErrorPage />
  }

  const hasLogo = isString(config.logo?.url)

  return (
    <div
      className={cx(
        'type-default h-dvh flex flex-col',
        hasLogo ? 'bg-[#EBEBED]' : 'bg-white'
      )}
      data-testid={testId}
    >
      {!!config.logo?.url ? (
        <LoginPageViewWithOrgLogo config={{ ...config, logo: config.logo }} />
      ) : (
        <LoginPageViewNoOrgLogo config={config} />
      )}
    </div>
  )
}

export default LoginPage
