import {
  getSSOConfigAssetURL,
  OrganizationConfig,
  OrganizationConfigResponseBody
} from '@local/shared'
import { useQuery } from '@tanstack/react-query'
import { isNumber } from '@toasttab/buffet-utils'
import yaml from 'js-yaml'
import { z } from 'zod'

const validateOrganizationConfigResponseBody = (data: unknown) => {
  const OrganizationConfigResponseBodySchema = z.object({
    organization_id: z.string(),
    organization_name: z.string(),
    sso_enabled: z.boolean(),
    logo: z
      .object({
        path: z.string().optional(),
        format: z.string().optional(),
        width: z.union([z.string(), z.number()]).optional(),
        height: z.union([z.string(), z.number()]).optional()
      })
      .optional(),
    idp_providers: z.array(
      z.object({
        provider: z.string(),
        name: z.string(),
        login_url: z.string()
      })
    )
  })

  const parsedResponse = OrganizationConfigResponseBodySchema.safeParse(data)

  if (!parsedResponse.success) {
    throw parsedResponse.error
  }

  return parsedResponse
}

const fetchOrganizationConfig = async (organizationSlug: string) => {
  const configURL = getSSOConfigAssetURL(organizationSlug, 'config.yml')
  const response = await fetch(configURL)
  const text = await response.text()
  const data = yaml.load(text)
  const validationResult = validateOrganizationConfigResponseBody(data)

  if (!validationResult.success) {
    throw validationResult.error
  }

  const validatedData: OrganizationConfigResponseBody = validationResult.data

  const logoURL =
    validatedData.logo?.path && validatedData.logo?.format
      ? getSSOConfigAssetURL(
          validatedData.organization_id,
          `${validatedData.logo.path}.${validatedData.logo.format}`
        )
      : undefined

  return {
    ssoEnabled: validatedData.sso_enabled,
    slug: validatedData.organization_id,
    name: validatedData.organization_name,
    logo: logoURL
      ? {
          url: logoURL,
          width: validatedData.logo?.width
            ? isNumber(validatedData.logo.width)
              ? `${validatedData.logo.width}px`
              : validatedData.logo.width
            : undefined,
          height: validatedData.logo?.height
            ? isNumber(validatedData.logo.height)
              ? `${validatedData.logo.height}px`
              : validatedData.logo.height
            : undefined
        }
      : undefined,
    identityProviders: validatedData.idp_providers.map((provider) => ({
      provider: provider.provider,
      name: provider.name,
      loginURL: provider.login_url
    }))
  } satisfies OrganizationConfig
}

export const useOrganizationConfig = (organizationSlug: string | undefined) => {
  const {
    data: config,
    isLoading: loading,
    error
  } = useQuery<OrganizationConfig, Error>({
    enabled: !!organizationSlug,
    queryKey: ['getOrganizationConfigByYAMLFile', organizationSlug],
    queryFn: () => {
      if (!organizationSlug) {
        throw new Error(
          `Organization slug is required, received: ${organizationSlug}`
        )
      }
      try {
        return fetchOrganizationConfig(organizationSlug)
      } catch (e) {
        console.error('Error fetching organization config', e)
        throw e
      }
    }
  })

  return {
    config,
    loading,
    error
  }
}
