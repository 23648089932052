import urlJoin from 'url-join'
import { PUBLIC_ASSETS_URL } from '@local/shared/environment'

export const getPublicAssetURL = (filePaths: string[]) => {
  return urlJoin(PUBLIC_ASSETS_URL, ...filePaths)
}

export const getSSOConfigAssetURL = (
  organizationSlug: string,
  filePath: string
) => {
  return getPublicAssetURL(['ssoConfig', organizationSlug, filePath])
}
