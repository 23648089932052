import { OrganizationConfig, OrganizationConfigLogo } from '@local/shared'
import { ToastBrand } from '@toasttab/buffet-pui-toast-logo'
import LoginPageBodyWithOrgLogo from '../LoginPageBodyWithOrgLogo/LoginPageBodyWithOrgLogo'
import LoginPageFooter from '../LoginPageFooter/LoginPageFooter'

type LoginPageViewWithOrgLogoProps = {
  config: OrganizationConfig & { logo: OrganizationConfigLogo }
}

const LoginPageViewWithOrgLogo = (props: LoginPageViewWithOrgLogoProps) => {
  const { config } = props

  return (
    <>
      <header className='min-h-[48px] h-[48px] sm:h-[64px] bg-white flex items-center justify-center fixed top-0 left-0 right-0 z-10'>
        <ToastBrand size='xxs' />
      </header>
      <main className='pt-[15dvh] sm:pt-[18dvh] mb-auto px-4'>
        <LoginPageBodyWithOrgLogo
          organizationName={config.name}
          logo={config.logo}
          identityProviders={config.identityProviders}
        />
      </main>
      <LoginPageFooter />
    </>
  )
}

export default LoginPageViewWithOrgLogo
